import bezier from 'bezier-easing';
import { useCallback, useEffect, useRef, useState } from 'react';
import { css, keyframes } from 'styled-components';

const flashAnimation = keyframes`
    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
`;

export const flash = (duration: number, delay = 0) => css`
  animation-name: ${flashAnimation};
  animation-duration: ${duration}s;
  animation-delay: ${delay}s;
`;

const pulseAnimation = (maxScale: number) => keyframes`
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(${maxScale}, ${maxScale}, ${maxScale});
    }

    to {
        transform: scale3d(1, 1, 1);
    }
`;

export const pulse = (maxScale: number, duration: number, delay = 0) => css`
  animation-name: ${pulseAnimation(maxScale)};
  animation-timing-function: ease-in-out;
  animation-duration: ${duration}s;
  animation-delay: ${delay}s;

  //@media (prefers-reduced-motion) {
  //  animation-name: none;
  //}
`;

const fadeInAnimation = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export const fadeIn = (duration: number, delay = 0) => css`
  animation-name: ${fadeInAnimation};
  animation-timing-function: ease-in-out;
  animation-duration: ${duration}s;
  animation-delay: ${delay}s;

  //@media (prefers-reduced-motion) {
  //  animation-name: none;
  //  opacity: 1;
  //}
`;

const fadeInDownAnimation = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

export const fadeInDown = (duration: number, delay = 0) => css`
  animation-name: ${fadeInDownAnimation};
  animation-duration: ${duration}s;
  animation-delay: ${delay}s;
`;

const rubberBandAnimation = keyframes`
    from {
        transform: scale3d(1, 1, 1);
    }

    30% {
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        transform: scale3d(1.05, 0.95, 1);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
`;

export const rubberBand = (duration: number, delay = 0) => css`
  animation-name: ${rubberBandAnimation};
  animation-duration: ${duration}s;
  animation-delay: ${delay}s;
`;

const slideInRightAnimation = keyframes`
    from {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
`;

export const slideInRight = (duration: number, delay = 0) => css`
  animation-name: ${slideInRightAnimation};
  animation-duration: ${duration}s;
  animation-delay: ${delay}s;
`;

const slideOutRightAnimation = keyframes`
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(100%, 0, 0);
    }
`;

export const slideOutRight = (duration: number, delay = 0) => css`
  animation-name: ${slideOutRightAnimation};
  animation-duration: ${duration}s;
  animation-delay: ${delay}s;
`;

export const useCounter = (
  end: number,
  duration: number,
  cubicBezier: { x1: number; y1: number; x2: number; y2: number },
  precision = 0,
  start = 0
) => {
  const [count, setCount] = useState<string>(start.toFixed(precision));

  const startTime = useRef(0);
  const frame = useRef<DOMHighResTimeStamp>();
  const lastEnd = useRef(0);
  const startValue = useRef(start);

  const step = useCallback(
    (timeStep: DOMHighResTimeStamp) => {
      if (startTime.current === 0) {
        startTime.current = timeStep;
      }

      const pastSeconds = (timeStep - startTime.current) / 1000;
      const pastPercentage = pastSeconds / duration;

      // Package to match css cubic-bezier behaviour.
      // todo: Package is a bit older and should be replaced again if necessary
      const easing = bezier(cubicBezier.x1, cubicBezier.y1, cubicBezier.x2, cubicBezier.y2);

      const newCount = startValue.current + easing(pastPercentage) * (end - startValue.current);

      setCount(newCount.toFixed(precision));

      if (pastSeconds > duration) {
        setCount(end.toFixed(precision));
        return;
      }

      frame.current = requestAnimationFrame(step);
    },
    [cubicBezier.x1, cubicBezier.x2, cubicBezier.y1, cubicBezier.y2, duration, end, precision]
  );

  useEffect(() => {
    if (end !== lastEnd.current && start !== end) {
      startValue.current = start === 0 ? parseFloat(count) : start;
      lastEnd.current = end;
      startTime.current = 0;
      frame.current = requestAnimationFrame(step);
    }

    return () => {
      if (frame.current) {
        cancelAnimationFrame(frame.current);
      }
    };
  }, [end, step]);

  return count;
};
