import * as Colyseus from 'colyseus.js';
import { useCallback, useEffect, useMemo } from 'react';

import { GlobalVisitorsState } from '@Api-ws-generated/GlobalVisitorsState';

import { getGlobalColyseusRoomClientId, setGlobalColyseusRoomClientId } from '@Components/context/LocalStorage';
import { MultiplayerCode, multiplayerConfig, MultiplayerMode } from '@Components/helper/colyseus/colyseusHelper';
import { useCompetitionListRefreshOnResultCreation } from '@Components/helper/colyseus/global/useCompetitionListRefreshOnResultCreation';
import { useGlobalVisitors } from '@Components/helper/colyseus/global/useGlobalVisitors';

import useGlobalColyseusRoomStore from '@Store/useGlobalColyseusRoomStore';

export const useGlobalColyseusRoom = () => {
  const config = multiplayerConfig[MultiplayerMode.GlobalRoom];
  const client = useMemo(() => new Colyseus.Client(process.env.NEXT_PUBLIC_WS_ROOT_URL), []);

  const { room, initRoom } = useGlobalColyseusRoomStore((state) => ({
    room: state.room,
    initRoom: state.initRoom,
  }));

  const { initGlobalVisitorsListener } = useGlobalVisitors(room);
  const { initCompetitionListeners } = useCompetitionListRefreshOnResultCreation(room);

  const connectToRoom = useCallback(async () => {
    try {
      let id = getGlobalColyseusRoomClientId();

      if (!id) {
        id = crypto.randomUUID();
        setGlobalColyseusRoomClientId(id);
      }

      const room = await client.joinOrCreate(config.name, { id }, GlobalVisitorsState);

      initRoom(room);
    } catch (e: unknown) {
      throw Error('Unexpected Error: could not join global colyseus room');
    }
  }, [client, config.name, initRoom]);

  const initListeners = useCallback(() => {
    room?.removeAllListeners();

    room?.onLeave((code) => {
      switch (code) {
        case MultiplayerCode.DefaultDisconnect:
        case MultiplayerCode.ConsentedDisconnect:
          break;
        case MultiplayerCode.UnexpectedError:
        default:
          throw Error(`Unexpected global colyseus room disconnect with code [${code}]`);
      }
    });

    initGlobalVisitorsListener();
    initCompetitionListeners();
  }, [initCompetitionListeners, initGlobalVisitorsListener, room]);

  useEffect(() => {
    const executeConnectToRoom = async () => {
      await connectToRoom();
    };

    executeConnectToRoom().catch((reason) => new Error(reason));
  }, [connectToRoom]);

  useEffect(() => {
    return () => {
      // consented false is required because otherwise there is a bug in the reconnection.
      // It only works correctly if the leaving of the room happened unintentionally
      // todo: this could be fixed in future colyseus versions, then this should be reconsidered
      room?.leave(false);
    };
  }, [room]);

  useEffect(() => {
    if (!room) {
      return;
    }
    initListeners();
  }, [initListeners, room]);
};
