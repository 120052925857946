import { IconBell, IconLanguage, IconPalette, IconProps, IconUsers } from '@tabler/icons-react';
import dynamic from 'next/dynamic';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';
import Container from '@Components/atoms/Container';
import { Icon } from '@Components/atoms/Icon/Icon';
import { LinkColor, LinkSize, LinkWeight } from '@Components/atoms/Link/Link';
import MenuButton from '@Components/atoms/MenuButton/MenuButton';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import Text from '@Components/atoms/Text';
import { Tooltip, TooltipContent, TooltipTrigger } from '@Components/atoms/Tooltip/Tooltip';
import { UserImageSize } from '@Components/atoms/UserImage/UserImage';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { useCounter } from '@Components/helper/animation';
import { useNotificationPolling } from '@Components/helper/useNotificationPolling';
import { LogoVariant } from '@Components/molecules/Logo/Logo';
import { Routes } from '@Components/Routes';
import useGlobalColyseusRoomStore from '@Store/useGlobalColyseusRoomStore';
import { AntiCheatGameResult } from '@Helpers/result';
import { TextColor, TextSize, TextTransform } from '@Helpers/types/text';
import { iconStroke } from '@Assets/styles/theme/theme';
import { Content, Counter, GameModeLink, HeaderRow, LoginSignupLink, MobileLogo, NotificationIcon, Root, StyledIconCompetition, StyledIconMultiplayer, StyledIconTypingtest, StyledRow, StyledUserImage, TabletDesktopLogo, Username, UserRow, VisitorCounter, VisitorsOnline } from './Header.styles';
const Sidebar = dynamic(() => import('@Components/molecules/Sidebar/Sidebar'), {
  ssr: false
});
const AntiCheatModal = dynamic(() => import('@Components/organisms/AntiCheatModal/AntiCheatModal'), {
  ssr: false
});
export type Content = 'default' | 'languages' | 'themes' | 'user' | 'notifications' | null;
interface Props extends PropsWithClassName {
  serverUser?: LocalStorageUserModel;
}
const Header = (props: Props): React.ReactElement => {
  const {
    serverUser
  } = props;
  const {
    t
  } = useTranslation('global');
  const {
    user: contextUser,
    isGlobalContextInitialized
  } = useGlobalContext();
  const [visitorsCounterInit, setVisitorsCounterInit] = useState(0);
  const {
    visitorCount,
    prevVisitorCount
  } = useGlobalColyseusRoomStore(state => ({
    visitorCount: state.visitorCount,
    prevVisitorCount: state.prevVisitorCount
  }));
  const visitorsCounter = useCounter(visitorsCounterInit ?? 0, 2, {
    x1: 0,
    y1: 1,
    x2: 0.2,
    y2: 0.96
  }, 0, prevVisitorCount);
  useEffect(() => {
    if (prevVisitorCount > 0) {
      setVisitorsCounterInit(visitorCount);
    }
  }, [visitorCount, prevVisitorCount]);
  const user = useMemo(() => isGlobalContextInitialized ? contextUser : serverUser, [contextUser, serverUser, isGlobalContextInitialized]);
  const [content, setContent] = useState<Content>(null);
  const [notifications, refreshNotifications] = useNotificationPolling(contextUser, 'app', content === 'notifications');
  const [isSidebarUnmounting, setSidebarIsUnmounting] = useState(false);
  const [result, setResult] = useState<AntiCheatGameResult | null>(null);
  const closeSidebar = useCallback(() => {
    if (content === null) {
      return;
    }
    setSidebarIsUnmounting(true);
    setTimeout(() => {
      setContent(null);
      setSidebarIsUnmounting(false);
    }, 500);
  }, [content]);
  useLayoutEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        closeSidebar();
      }
    };
    window.addEventListener('keydown', handleEscapeKey);
    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, [closeSidebar]);
  const handleIconClick = (clickedContent: Content) => {
    if (isSidebarUnmounting) {
      return;
    }
    if (content === clickedContent) {
      closeSidebar();
      return;
    }
    setContent(clickedContent);
  };
  const renderIcon = (icon: (props: IconProps) => React.ReactNode, currentContent: Content) => <Icon icon={icon} stroke={iconStroke} onClick={() => handleIconClick(currentContent)} color={content === currentContent && !isSidebarUnmounting ? TextColor.ThemePrimary : TextColor.Header} dataTestId={`${currentContent}-trigger-root`} hoverColor={TextColor.ThemePrimary} data-sentry-element="Icon" data-sentry-component="renderIcon" data-sentry-source-file="Header.tsx" />;
  const renderUserLoginOrUserArea = () => {
    if (user) {
      let counter = 0;
      if (notifications && notifications.length > 0) {
        const unreadNotifications = notifications.filter(notification => notification.readAt === null);
        counter = unreadNotifications.length;
      }
      return <>
          <NotificationIcon onClick={() => handleIconClick('notifications')}>
            {counter > 0 && <Counter>{counter}</Counter>}
            {renderIcon(IconBell, 'notifications')}
          </NotificationIcon>
          <div onClick={() => handleIconClick('user')}>
            <UserRow noGutters rowGap={RowGap.Small} xsMiddle dataTestId="user-trigger-root">
              <StyledUserImage $isOpen={content === 'user' && !isSidebarUnmounting} width={34} height={34} userImageSize={UserImageSize.Small} userImageSrcModel={user.gravatar} />
              <Username size={TextSize.Medium} color={content === 'user' && !isSidebarUnmounting ? TextColor.ThemePrimary : TextColor.Header} dataTestId="user-trigger-username">
                {user.username}
              </Username>
            </UserRow>
          </div>
        </>;
    }
    return <StyledRow noGutters rowGap={RowGap.Default} data-sentry-element="StyledRow" data-sentry-component="renderUserLoginOrUserArea" data-sentry-source-file="Header.tsx">
        <LoginSignupLink href={Routes.Login} size={LinkSize.Medium} weight={LinkWeight.Light} onClick={closeSidebar} hideUnderline dataTestId="header-button-login" data-sentry-element="LoginSignupLink" data-sentry-source-file="Header.tsx">
          {t('navigation.header.login')}
        </LoginSignupLink>
        <LoginSignupLink href={Routes.SignUp} size={LinkSize.Medium} weight={LinkWeight.Light} onClick={closeSidebar} hideUnderline dataTestId="header-button-signup" data-sentry-element="LoginSignupLink" data-sentry-source-file="Header.tsx">
          {t('navigation.header.signup')}
        </LoginSignupLink>
      </StyledRow>;
  };
  return <>
      <Root className={props.className} data-testid={'Header-root'} data-sentry-element="Root" data-sentry-source-file="Header.tsx">
        <Container data-sentry-element="Container" data-sentry-source-file="Header.tsx">
          <HeaderRow xsBetween noGutters xsMiddle data-sentry-element="HeaderRow" data-sentry-source-file="Header.tsx">
            <TabletDesktopLogo height={32} onClick={closeSidebar} dataTestId="header-logo" isHeader data-sentry-element="TabletDesktopLogo" data-sentry-source-file="Header.tsx" />
            <MobileLogo height={32} onClick={closeSidebar} variant={LogoVariant.Logo} dataTestId="header-logo-mobile" isHeader data-sentry-element="MobileLogo" data-sentry-source-file="Header.tsx" />
            <Content rowGap={RowGap.Large} noGutters xsMiddle data-sentry-element="Content" data-sentry-source-file="Header.tsx">
              {visitorCount > 0 && <VisitorsOnline>
                  <Tooltip>
                    <TooltipContent isSmall>
                      <Text size={TextSize.Medium}>{t('navigation.visitor_count.tooltip')}</Text>
                    </TooltipContent>
                    <TooltipTrigger open={false}>
                      <Row noGutters xsMiddle rowGap={RowGap.Small}>
                        <Icon icon={IconUsers} strokeWidth={iconStroke} color={TextColor.Header} />
                        <VisitorCounter size={TextSize.Medium} color={TextColor.Header}>
                          {visitorsCounter === '0' ? visitorCount : visitorsCounter}
                        </VisitorCounter>
                      </Row>
                    </TooltipTrigger>
                  </Tooltip>
                </VisitorsOnline>}
              <GameModeLink href={Routes.TypingTest} weight={LinkWeight.Light} size={LinkSize.Medium} color={LinkColor.Header} dataTestId={'navigation-main-typingtest'} transform={TextTransform.Capitalize} onClick={closeSidebar} hideUnderline data-sentry-element="GameModeLink" data-sentry-source-file="Header.tsx">
                <StyledIconTypingtest size={24} isHeader data-sentry-element="StyledIconTypingtest" data-sentry-source-file="Header.tsx" />
                {t('mode.typing_test')}
              </GameModeLink>
              <GameModeLink href={Routes.Competitions} weight={LinkWeight.Light} size={LinkSize.Medium} color={LinkColor.Header} dataTestId={'navigation-main-competition'} transform={TextTransform.Capitalize} onClick={closeSidebar} hideUnderline data-sentry-element="GameModeLink" data-sentry-source-file="Header.tsx">
                <StyledIconCompetition size={24} isHeader data-sentry-element="StyledIconCompetition" data-sentry-source-file="Header.tsx" />
                {t('mode.competition')}
              </GameModeLink>
              <GameModeLink href={Routes.Multiplayer} weight={LinkWeight.Light} size={LinkSize.Medium} color={LinkColor.Header} transform={TextTransform.Capitalize} onClick={closeSidebar} dataTestId={'navigation-main-multiplayer'} hideUnderline data-sentry-element="GameModeLink" data-sentry-source-file="Header.tsx">
                <StyledIconMultiplayer size={24} isHeader data-sentry-element="StyledIconMultiplayer" data-sentry-source-file="Header.tsx" />
                {t('mode.multiplayer')}
              </GameModeLink>
              {renderIcon(IconPalette, 'themes')}
              {renderIcon(IconLanguage, 'languages')}
              {renderUserLoginOrUserArea()}
              <MenuButton isOpen={content === 'default' && !isSidebarUnmounting} onClick={() => handleIconClick('default')} data-sentry-element="MenuButton" data-sentry-source-file="Header.tsx" />
            </Content>
          </HeaderRow>
        </Container>
      </Root>
      {content && <Sidebar content={content} isUnmounting={isSidebarUnmounting} handleCloseSidebar={closeSidebar} notifications={notifications} refreshNotifications={refreshNotifications} handleAntiCheatResultUpdate={setResult} />}
      {result && <AntiCheatModal result={result} onClose={() => setResult(null)} onSuccess={() => setResult(null)} />}
    </>;
};
export default Header;