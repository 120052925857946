import { Room } from 'colyseus.js';
import { useCallback } from 'react';

import { GlobalVisitorsState } from '@Api-ws-generated/GlobalVisitorsState';

import { GlobalVisitorsMessage } from '@Components/helper/colyseus/colyseusHelper';

import useGlobalColyseusRoomStore from '@Store/useGlobalColyseusRoomStore';

export const useGlobalVisitors = (room: Room<GlobalVisitorsState> | null) => {
  const { visitorCount, updateVisitorCount, updatePrevVisitorCount } = useGlobalColyseusRoomStore((state) => ({
    visitorCount: state.visitorCount,
    updateVisitorCount: state.updateVisitorCount,
    updatePrevVisitorCount: state.updatePrevVisitorCount,
  }));

  const initGlobalVisitorsListener = useCallback(() => {
    room?.onMessage(GlobalVisitorsMessage.VisitorList, (newVisitors) => {
      updatePrevVisitorCount(visitorCount);
      updateVisitorCount(newVisitors);
    });
  }, [room, updatePrevVisitorCount, updateVisitorCount, visitorCount]);

  return { initGlobalVisitorsListener };
};
