import { Room } from 'colyseus.js';
import { usePathname } from 'next/navigation';
import { useCallback } from 'react';

import { GlobalVisitorsState } from '@Api-ws-generated/GlobalVisitorsState';

import { CompetitionMessage } from '@Components/helper/colyseus/colyseusHelper';
import { Routes } from '@Components/Routes';

import useGlobalColyseusRoomStore from '@Store/useGlobalColyseusRoomStore';

export const useCompetitionListRefreshOnResultCreation = (room: Room<GlobalVisitorsState> | null) => {
  const pathname = usePathname();

  const fetchRunningCompetitions = useGlobalColyseusRoomStore((state) => state.fetchRunningCompetitions);

  const initCompetitionListeners = useCallback(() => {
    if (room && pathname === Routes.Competitions) {
      room.onMessage(CompetitionMessage.RefreshList, fetchRunningCompetitions);
    }
  }, [fetchRunningCompetitions, pathname, room]);

  return { initCompetitionListeners };
};
