import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { MiddleLine, Root } from './MenuButton.styles';
interface Props extends PropsWithClassName {
  isOpen: boolean;
  onClick: (isOpen: boolean) => void;
}
const MenuButton = (props: Props): React.ReactElement => {
  const {
    className,
    dataTestId,
    isOpen,
    onClick
  } = props;
  return <Root className={className} data-testid={dataTestId ?? 'menu-button-root'} $isOpen={isOpen} onClick={() => onClick(!isOpen)} data-sentry-element="Root" data-sentry-component="MenuButton" data-sentry-source-file="MenuButton.tsx">
      <MiddleLine $isOpen={isOpen} data-sentry-element="MiddleLine" data-sentry-source-file="MenuButton.tsx" />
    </Root>;
};
export default MenuButton;