import { IconBrandDiscordFilled, IconBrandFacebook, IconBrandX } from '@tabler/icons-react';
import React from 'react';
import { LinkSize, LinkWeight } from '@Components/atoms/Link/Link';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import { PropsWithClassName } from '@Components/helper';
import { StyledLink } from './SocialIcons.styles';
const socialIcons = [{
  icon: <IconBrandDiscordFilled />,
  url: 'https://discord.gg/4KypVEM',
  dataTestId: 'social-icons-discord',
  label: 'Discord'
}, {
  icon: <IconBrandFacebook />,
  url: 'https://www.facebook.com/10fastfingers',
  dataTestId: 'social-icons-facebook',
  label: 'Facebook'
}, {
  icon: <IconBrandX />,
  url: 'https://x.com/10fastfingers_',
  dataTestId: 'social-icons-x',
  label: 'X'
}];
interface Props extends PropsWithClassName {}
const SocialIcons = (props: Props): React.ReactElement => {
  return <div className={props.className} data-testid={props.dataTestId ?? 'social-icons-root'} data-sentry-component="SocialIcons" data-sentry-source-file="SocialIcons.tsx">
      <Row noGutters rowGap={RowGap.Large} data-sentry-element="Row" data-sentry-source-file="SocialIcons.tsx">
        {socialIcons.map((socialIcon, index) => <StyledLink size={LinkSize.Medium} weight={LinkWeight.Light} href={socialIcon.url} key={index} target="_blank" rel="noreferrer" dataTestId={socialIcon.dataTestId} label={socialIcon.label}>
            {socialIcon.icon}
          </StyledLink>)}
      </Row>
    </div>;
};
export default SocialIcons;