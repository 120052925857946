import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { CompetitionMessage } from '@Components/helper/colyseus/colyseusHelper';

import { GlobalColyseusRoomActions, GlobalColyseusRoomState } from '@Store/useColyseusStore.types';

const useGlobalColyseusRoomStore = create<GlobalColyseusRoomState & GlobalColyseusRoomActions>()(
  devtools((set, get): GlobalColyseusRoomState & GlobalColyseusRoomActions => ({
    room: null,
    initRoom: (room) => {
      set({ room });
    },
    // HEADER VISITORS
    visitorCount: 0,
    prevVisitorCount: 0,
    updateVisitorCount: (newVisitorCount) => {
      set({
        visitorCount: newVisitorCount,
      });
    },
    updatePrevVisitorCount: (newPrevVisitorCount) => {
      set({
        prevVisitorCount: newPrevVisitorCount,
      });
    },
    // COMPETITIONS
    createCompetitionResult: () => {
      get().room?.send(CompetitionMessage.ResultCreated);
    },
    fetchRunningCompetitions: () => {},
    updateRunningCompetitions: (func) => {
      set({
        fetchRunningCompetitions: func,
      });
    },
  }))
);
export default useGlobalColyseusRoomStore;
