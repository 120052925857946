// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.7
// 

import { Schema, type, ArraySchema, MapSchema, SetSchema, DataChange } from '@colyseus/schema';
import { VisitorIdSet } from './VisitorIdSet'

export class GlobalVisitorsState extends Schema {
    @type({ map: "string" }) public visitorsClientIdToUniqueDeviceId: MapSchema<string> = new MapSchema<string>();
    @type({ map: VisitorIdSet }) public visitorsUniqueDeviceIdToClientId: MapSchema<VisitorIdSet> = new MapSchema<VisitorIdSet>();
}
